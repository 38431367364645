import { MWCampaign, MWCampaignBE, MWUpdateCampaign, MWUpdateCampaignBE } from '@/types/message-whale/mwCampaign';
import { mapMWMessagesBEsToDTO, mapMWMessagesDTOToBEForUpdate } from './mapMessageWhaleMessageDTO';

export const mapMWCampaignBEsToDTO = (campaignBE: MWCampaignBE): MWCampaign => {
  return {
    id: campaignBE.id.toString(),
    name: campaignBE.name,
    status: campaignBE.status,
    trigger: campaignBE.trigger,
    scheduledAt: campaignBE.scheduled_at,
    pausedAt: campaignBE.paused_at,
    startedAt: campaignBE.started_at,
    finishedAt: campaignBE.finished_at,
    senderName: campaignBE.sender_name,
    senderEmail: campaignBE.sender_email,
    recipients: campaignBE.recipients,
    created: campaignBE.created_at,
    updated: campaignBE.updated_at,
    stats: campaignBE.stats,
    messages: campaignBE.messages ? mapMWMessagesBEsToDTO(campaignBE.messages) : undefined,
  };
};

export const mapMWCampaignsBEsToDTO = (
  campaignBEArray: MWCampaignBE[]
): MWCampaign[] => {
  return campaignBEArray.map((campaignBE) => {
    return mapMWCampaignBEsToDTO(campaignBE);
  });
};

export const mapMWCampaignDTOToBEForCreate = (
  campaign: Partial<MWCampaign>
): Partial<MWCampaignBE> => {
  return {
    name: campaign.name,
  };
};

export const mapMWCampaignDTOToBEForUpdate = (
  campaign: Partial<MWUpdateCampaign>
): Partial<MWUpdateCampaignBE> => {
  return {
    name: campaign.name,
    status: campaign.status,
    trigger: campaign.trigger,
    scheduled_at: campaign.scheduledAt,
    paused_at: campaign.pausedAt,
    started_at: campaign.startedAt,
    finished_at: campaign.finishedAt,
    sender_name: campaign.senderName,
    sender_email: campaign.senderEmail,
    recipients: campaign.recipients,
    messages: mapMWMessagesDTOToBEForUpdate(campaign.messages),
  };
};
