import { useLocation } from 'react-router-dom'

export function useCheckActiveNav() {
  const { pathname } = useLocation()

  const checkActiveNav = (nav: string) => {
    const pathArray = pathname.split('/').filter((item) => item !== '')
    const navArray = nav.split('/').filter((item) => item !== '')

    if (pathArray.length < 2 || navArray.length < 2) {
      return pathArray[0] === navArray[0];
    }

    return pathArray[0] === navArray[0] && pathArray[1] === navArray[1]
  }

  return { checkActiveNav }
}
