import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import { InputPasteButton } from '../InputPasteButton';
// import { format } from 'date-fns';
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from '../ui/select';
// import { Calendar } from '../ui/calendar';
// import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
// import { Calendar as CalendarIcon } from 'lucide-react';
// import {
//   cn,
//   convertDateToBackendFormat,
//   handleCheckboxChange,
// } from '@/lib/utils';
import { useCreateMWCampaign } from '@/hooks/message-whale/campaigns/useCreateMWCampaign';
import { toast } from 'sonner';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { useNavigate } from 'react-router-dom';

export const CreateMWCampaignModal = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { closeModal } = useModalContext();

  const handleClose = () => {
    closeModal('createNewMWCampaign');
  };

  const [name, setName] = useState('');
  // const [startDate, setStartDate] = useState<Date | undefined>();

  const { mutate: createCampaign, isPending: isCreatingCampaign } =
    useCreateMWCampaign({
      onSuccess: () => {
        toast.success(t('campaignCreatedSuccessfully'));
        handleClose();
        navigate('/mw/campaigns');
      },
      onError: () => {
        toast.error(t('failedToCreateCampaign'));
      },
    });

  const handleSave = () => {
    createCampaign({
      name,
      // startDate,
    });
  };

  const handlePaste = async (
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const text = await navigator.clipboard.readText();
      setValue(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  return (
    <Modal modalType="createNewMWCampaign" title={t('createNewCampaign')}>
      <div className="space-y-4">
        <div className="relative">
          <Label htmlFor="campaign-name">{t('campaignName')}</Label>
          <Input
            id="campaign-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setName)} />
        </div>
        {/* <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
          <Label htmlFor="start-date" className="col-span-1">
            {t('campaignStartDate')}
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal col-span-1',
                  !startDate && 'text-muted-foreground'
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {startDate ? (
                  format(startDate, 'PPP')
                ) : (
                  <span>{t('pickAStartDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                id="start-date"
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                initialFocus
                fromDate={new Date()}
              />
            </PopoverContent>
          </Popover>
        </div> */}
        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isCreatingCampaign}
            className="flex gap-1"
            variant="conversionCraft"
          >
            {isCreatingCampaign && <LoadingIndicator size="small" />}
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
