import { MWList, MWListBE } from '@/types/message-whale/mwList';

export const mapMWListBEsToDTO = (listBE: MWListBE): MWList => {
  return {
    id: listBE.id.toString(),
    name: listBE.name,
    created: listBE.created_at,
    updated: listBE.updated_at,
    subscribersCount: listBE.subscribers_count,
  };
};

export const mapMWListsBEsToDTO = (
  listBEArray: MWListBE[]
): MWList[] => {
  return listBEArray.map((listBE) => {
    return mapMWListBEsToDTO(listBE);
  });
};

export const mapMWListDTOToBEForCreate = (
  list: Partial<MWList>
): Partial<MWListBE> => {
  return {
    name: list.name,
  };
};

export const mapMWListDTOToBEForUpdate = (
  list: Partial<MWList>
): Partial<MWListBE> => {
  return {
    name: list.name,
  };
};
