import { toast } from 'sonner';

import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useState } from 'react';
import { Label } from '../ui/label';
// import { Input } from '../ui/input';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
// import { InputPasteButton } from '../InputPasteButton';
import { useCreateMWMessage } from '@/hooks/message-whale/messages/useCreateMWMessage';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { useResourceStore } from '@/stores/resource';
import { MW_MESSAGE_TYPES, MWMessageType } from '@/types/message-whale/mwMessage';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
// import { useNavigate } from 'react-router-dom';

export const CreateMWMessageModal = () => {
  const { t } = useTranslation('common');
  const selectedCampaign = useResourceStore(state => state.selectedResource);
  // const navigate = useNavigate();
  const { closeModal } = useModalContext();

  const handleClose = () => {
    closeModal('createNewMWMessage');
  };

  // const [name, setName] = useState('');
  const [messageType, setMessageType] = useState<MWMessageType>(MWMessageType.VIBER);

  const { mutate: createMessage, isPending: isCreatingMessage } =
    useCreateMWMessage({
      onSuccess: () => {
        toast.success(t('messageCreatedSuccessfully'));
        handleClose();
        // navigate('/mw/campaigns');
      },
      onError: () => {
        toast.error(t('failedToCreateMessage'));
      },
    });

  const handleSave = () => {
    createMessage({
      // name,
      type: messageType,
      campaignId: selectedCampaign,
      // startDate,
    });
  };

  // const handlePaste = async (
  //   setValue: React.Dispatch<React.SetStateAction<string>>
  // ) => {
  //   try {
  //     const text = await navigator.clipboard.readText();
  //     setValue(text);
  //   } catch (err) {
  //     console.error('Failed to read clipboard contents: ', err);
  //   }
  // };

  return (
    <Modal modalType="createNewMWMessage" title={t('createNewMessage')}>
      <div className="space-y-4">
        {/* <div className="relative">
          <Label htmlFor="message-name">{t('messageName')}</Label>
          <Input
            id="message-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setName)} />
        </div> */}
        <div className="relative">
          <Label htmlFor="message-name">{t('messageType')}</Label>
          <Select value={messageType} onValueChange={(value) => setMessageType(value as MWMessageType)}>
            <SelectTrigger>
              <SelectValue placeholder={t('selectMessageType')} />
            </SelectTrigger>
            <SelectContent>
              {MW_MESSAGE_TYPES.map(type => (
                <SelectItem key={type} value={type}>
                  {t(`messageTypes.${type}`)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {/* <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
          <Label htmlFor="start-date" className="col-span-1">
            {t('campaignStartDate')}
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal col-span-1',
                  !startDate && 'text-muted-foreground'
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {startDate ? (
                  format(startDate, 'PPP')
                ) : (
                  <span>{t('pickAStartDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                id="start-date"
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                initialFocus
                fromDate={new Date()}
              />
            </PopoverContent>
          </Popover>
        </div> */}
        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isCreatingMessage}
            className="flex gap-1"
            variant="conversionCraft"
          >
            {isCreatingMessage && <LoadingIndicator size="small" />}
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
