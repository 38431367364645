import { validate as validateUUID } from 'uuid';
import { MWMessage, MWMessageBE } from '@/types/message-whale/mwMessage';

export const mapMWMessageBEsToDTO = (messageBE: MWMessageBE): MWMessage => {
  return {
    id: messageBE.id?.toString() ?? '',
    created: messageBE.created_at,
    updated: messageBE.updated_at,
    campaignId: messageBE.campaign_id,
    templateId: messageBE.template_id,
    channelId: messageBE.channel_id,
    content: messageBE.content,
    type: messageBE.type,
  };
};

export const mapMWMessagesBEsToDTO = (
  messageBEArray: MWMessageBE[]
): MWMessage[] => {
  return messageBEArray.map((messageBE) => {
    return mapMWMessageBEsToDTO(messageBE);
  });
};

export const mapMWMessageDTOToBEForCreate = (
  message: Partial<MWMessage>
): Partial<MWMessageBE> => {
  return {
    campaign_id: message.campaignId,
    type: message.type,
  };
};

function getMessageId(id?: string): number | undefined | null {
  if (!id) {
    return undefined;
  }

  if (validateUUID(id)) {
    return null;
  }

  try {
    return parseInt(id);
  } catch (e) {
    return null;
  }
}

export const mapMWMessageDTOToBEForUpdate = (
  message: Partial<MWMessage>
): Partial<MWMessageBE> => {
  return {
    id: getMessageId(message.id),
    content: message.content,
    campaign_id: message.campaignId,
    template_id: message.templateId,
    channel_id: message.channelId,
    type: message.type,
  };
};

export const mapMWMessagesDTOToBEForUpdate = (
  messages?: Partial<MWMessage>[]
): Partial<MWMessageBE>[] => {
  return messages?.map((message) => {
    return mapMWMessageDTOToBEForUpdate(message);
  }) || [];
}
