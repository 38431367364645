import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';


export type ResourceStore = {
  selectedResource: string|null;
  setSelectedResource: (resource: string|null) => void;
};

export const useResourceStore = create<ResourceStore>()(
  devtools(
    (set) => ({
      selectedResource: null,
      setSelectedResource: (resource: string|null) => {
        set(
          produce((state: ResourceStore): void => {
            state.selectedResource = resource;
          })
        );
      },
    })
  )
);
