import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { MW_LISTS } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { MWListBE } from '@/types/message-whale/mwList';
import { mapMWListDTOToBEForCreate } from '@/mappers/mapMessageWhaleListDTO';

const LISTS_QUERY_KEY = [MW_LISTS];

export const useCreateMWList = <MWList>({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<MWListBE>({
    url: endpoints.mwLists(),
    method: 'post',
  });

  return useMutation<
    SendRequestResponse<MWListBE>,
    Error,
    Partial<MWList>,
    { previousLists: MWListBE[] | undefined }
  >({
    mutationFn: async (data: Partial<MWList>) => {
      const mappedData = mapMWListDTOToBEForCreate(data);
      const response = await sendRequest(mappedData);

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    // With onMutate, we can update the cache before the mutation is executed
    onMutate: async (data: Partial<MWListBE>) => {
      await queryClient.cancelQueries(LISTS_QUERY_KEY as QueryFilters);

      const previousLists = queryClient.getQueryData<MWListBE[]>([
        MW_LISTS,
      ]);

      queryClient.setQueryData<Partial<MWListBE>[]>([MW_LISTS], (old) => {
        if (old) {
          return [...old, data];
        }
        return [data];
      });

      return { previousLists };
    },
    onError: (_, __, context) => {
      console.log('ERROR');
      if (context?.previousLists) {
        queryClient.setQueryData([MW_LISTS], context.previousLists);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(LISTS_QUERY_KEY as InvalidateQueryFilters);
      onSuccess?.();
    },
  });
};
