import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { MW_MESSAGES } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { MWMessageBE } from '@/types/message-whale/mwMessage';
import { mapMWMessageDTOToBEForCreate } from '@/mappers/mapMessageWhaleMessageDTO';

const MESSAGES_QUERY_KEY = [MW_MESSAGES];

export const useCreateMWMessage = <MWMessage>({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<MWMessageBE>({
    url: endpoints.mwMessages(),
    method: 'post',
  });

  return useMutation<
    SendRequestResponse<MWMessageBE>,
    Error,
    Partial<MWMessage>,
    { previousMessages: MWMessageBE[] | undefined }
  >({
    mutationFn: async (data: Partial<MWMessage>) => {
      const mappedData = mapMWMessageDTOToBEForCreate(data);
      const response = await sendRequest(mappedData);

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    // With onMutate, we can update the cache before the mutation is executed
    onMutate: async (data: Partial<MWMessageBE>) => {
      await queryClient.cancelQueries(MESSAGES_QUERY_KEY as QueryFilters);

      const previousMessages = queryClient.getQueryData<MWMessageBE[]>([
        MW_MESSAGES,
      ]);

      queryClient.setQueryData<Partial<MWMessageBE>[]>([MW_MESSAGES], (old) => {
        if (old) {
          return [...old, data];
        }
        return [data];
      });

      return { previousMessages };
    },
    onError: (_, __, context) => {
      console.log('ERROR');
      if (context?.previousMessages) {
        queryClient.setQueryData([MW_MESSAGES], context.previousMessages);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(MESSAGES_QUERY_KEY as InvalidateQueryFilters);
      onSuccess?.();
    },
  });
};
