import { MWChannel, MWChannelBE } from '@/types/message-whale/mwChannel';

export const mapMWChannelBEsToDTO = (channelBE: MWChannelBE): MWChannel => {
  return {
    id: channelBE.id.toString(),
    name: channelBE.name,
    created: channelBE.created_at,
    updated: channelBE.updated_at,
    type: channelBE.type,
    settings: channelBE.settings ?? {},
  };
};

export const mapMWChannelsBEsToDTO = (
  channelBEArray: MWChannelBE[]
): MWChannel[] => {
  return channelBEArray.map((channelBE) => {
    return mapMWChannelBEsToDTO(channelBE);
  });
};

export const mapMWChannelDTOToBEForCreate = (
  channel: Partial<MWChannel>
): Partial<MWChannelBE> => {
  return {
    name: channel.name,
    type: channel.type,
    settings: channel.settings,
  };
};

export const mapMWChannelDTOToBEForUpdate = (
  channel: Partial<MWChannel>
): Partial<MWChannelBE> => {
  return {
    name: channel.name,
    type: channel.type,
    settings: channel.settings,
  };
};
