import React from 'react';
import { AuthContext, AuthContextParams } from '../hooks/useAuth';
import { useUserStore } from '../stores/user';
import { PermissionType, UserType } from '@/types/user';
import { useOrganizationStore } from '@/stores/organization';

const emptyUser: UserType = {
  id: '',
  name: '',
  emailVerifiedAt: null,
  email: '',
  updated: null,
  created: '',
  role: 'viewer',
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    login: stateLogin,
    logout: stateLogout,
    user,
    permissions: userPermissions,
    isLoggedIn,
    token,
  } = useUserStore();

  // const selectedClient = useClientStore((state) => state.client);
  const selectedOrganization = useOrganizationStore((state) => state.organization);

  const value: AuthContextParams = {
    user: user || emptyUser,
    token: token || '',
    permissions: userPermissions,
    setToken: (token: string) => {
      localStorage.setItem('token', token);
    },
    client: selectedOrganization?.id.toString() ?? null,
    isLoggedIn,
    login: (
      newUser: UserType,
      authResponse: {
        access_token: string;
        token_type: string;
        expires_in: string;
      },
      permissions: PermissionType[],
      callback: VoidFunction
    ) => {
      const { access_token } = authResponse;
      localStorage.setItem('token', access_token);
      stateLogin(newUser, access_token, permissions);
      callback();
    },
    logout: (callback?: VoidFunction) => {
      stateLogout();
      if (callback) {
        callback();
      }
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.displayName = 'AuthProvider';

export default AuthProvider;
