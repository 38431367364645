import commonEN from './en/common.json';
import commonHU from './hu/common.json';

import integrationsEN from './en/integrations.json';
import integrationsHU from './hu/integrations.json';

import messageWhaleEN from './en/messageWhale.json';
import messageWhaleHU from './hu/messageWhale.json';

export const resources = {
  'en-US': {
    common: commonEN,
    integrations: integrationsEN,
    messageWhale: messageWhaleEN,
  },
  'hu-HU': {
    common: commonHU,
    integrations: integrationsHU,
    messageWhale: messageWhaleHU,
  },
};
