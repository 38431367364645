import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import { InputPasteButton } from '../InputPasteButton';
import { useCreateMWList } from '@/hooks/message-whale/lists/useCreateMWList';
import { toast } from 'sonner';
import { LoadingIndicator } from '../common/LoadingIndicator';

export const CreateMWListModal = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalContext();

  const handleClose = () => {
    closeModal('createNewMWList');
  };

  const [name, setName] = useState('');

  const { mutate: createList, isPending: isCreatingList } = useCreateMWList({
    onSuccess: () => {
      toast.success(t('listCreatedSuccessfully'));
        handleClose();
      },
      onError: () => {
        toast.error(t('failedToCreateCampaign'));
      },
    });

  const handleSave = () => {
    createList({
      name,
    });
  };

  const handlePaste = async (
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const text = await navigator.clipboard.readText();
      setValue(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  return (
    <Modal modalType="createNewMWList" title={t('createNewList')}>
      <div className="space-y-4">
        <div className="relative">
          <Label htmlFor="list-name">{t('name')}</Label>
          <Input
            id="list-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setName)} />
        </div>

        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isCreatingList}
            className="flex gap-1"
            variant="conversionCraft"
          >
            {isCreatingList && <LoadingIndicator size="small" />}
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
