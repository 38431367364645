import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { MW_SEGMENTS } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { MWSegmentBE } from '@/types/message-whale/mwSegment';
import { mapMWSegmentDTOToBEForCreate } from '@/mappers/mapMessageWhaleSegmentDTO';

const SEGMENTS_QUERY_KEY = [MW_SEGMENTS];

export const useCreateMWSegment = <MWSegment>({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<MWSegmentBE>({
    url: endpoints.mwSegments(),
    method: 'post',
  });

  return useMutation<
    SendRequestResponse<MWSegmentBE>,
    Error,
    Partial<MWSegment>,
    { previousLists: MWSegmentBE[] | undefined }
  >({
    mutationFn: async (data: Partial<MWSegment>) => {
      const mappedData = mapMWSegmentDTOToBEForCreate(data);
      const response = await sendRequest(mappedData);

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    // With onMutate, we can update the cache before the mutation is executed
    onMutate: async (data: Partial<MWSegmentBE>) => {
      await queryClient.cancelQueries(SEGMENTS_QUERY_KEY as QueryFilters);

      const previousLists = queryClient.getQueryData<MWSegmentBE[]>([
        MW_SEGMENTS,
      ]);

      queryClient.setQueryData<Partial<MWSegmentBE>[]>([MW_SEGMENTS], (old) => {
        if (old) {
          return [...old, data];
        }
        return [data];
      });

      return { previousLists };
    },
    onError: (_, __, context) => {
      console.log('ERROR');
      if (context?.previousLists) {
        queryClient.setQueryData([MW_SEGMENTS], context.previousLists);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(SEGMENTS_QUERY_KEY as InvalidateQueryFilters);
      onSuccess?.();
    },
  });
};
