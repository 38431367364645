import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { MW_CHANNELS } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { MWChannelBE } from '@/types/message-whale/mwChannel';
import { mapMWChannelDTOToBEForCreate } from '@/mappers/mapMessageWhaleChannelDTO';

const CHANNELS_QUERY_KEY = [MW_CHANNELS];

export const useCreateMWChannel = <MWChannel>({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<MWChannelBE>({
    url: endpoints.mwChannels(),
    method: 'post',
  });

  return useMutation<
    SendRequestResponse<MWChannelBE>,
    Error,
    Partial<MWChannel>,
    { previousChannels: MWChannelBE[] | undefined }
  >({
    mutationFn: async (data: Partial<MWChannel>) => {
      const mappedData = mapMWChannelDTOToBEForCreate(data);
      const response = await sendRequest(mappedData);

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    // With onMutate, we can update the cache before the mutation is executed
    onMutate: async (data: Partial<MWChannelBE>) => {
      await queryClient.cancelQueries(CHANNELS_QUERY_KEY as QueryFilters);

      const previousChannels = queryClient.getQueryData<MWChannelBE[]>([
        MW_CHANNELS,
      ]);

      queryClient.setQueryData<Partial<MWChannelBE>[]>([MW_CHANNELS], (old) => {
        if (old) {
          return [...old, data];
        }
        return [data];
      });

      return { previousChannels };
    },
    onError: (_, __, context) => {
      console.log('ERROR');
      if (context?.previousChannels) {
        queryClient.setQueryData([MW_CHANNELS], context.previousChannels);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(CHANNELS_QUERY_KEY as InvalidateQueryFilters);
      onSuccess?.();
    },
  });
};
