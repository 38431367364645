import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import { InputPasteButton } from '../InputPasteButton';
import { useCreateMWChannel } from '@/hooks/message-whale/channels/useCreateMWChannel';
import { toast } from 'sonner';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { MWMessageType } from '@/types/message-whale/mwMessage';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';

export const CreateMWChannelModal = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalContext();

  const handleClose = () => {
    closeModal('createNewMWChannel');
  };

  const [name, setName] = useState('');
  const [type, setType] = useState<MWMessageType>(MWMessageType.VIBER);

  const { mutate: createChannel, isPending: isCreatingChannel } = useCreateMWChannel({
    onSuccess: () => {
      toast.success(t('channelCreatedSuccessfully'));
        handleClose();

        setName('');
        setType(MWMessageType.VIBER);
      },
      onError: () => {
        toast.error(t('failedToCreateCampaign'));
      },
    });

  const handleSave = () => {
    createChannel({
      name,
      type,
    });
  };

  const handlePaste = async (
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const text = await navigator.clipboard.readText();
      setValue(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  return (
    <Modal modalType="createNewMWChannel" title={t('createNewChannel')}>
      <div className="space-y-4">
        <div className="relative">
          <Label htmlFor="list-name">{t('name')}</Label>
          <Input
            id="list-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setName)} />
        </div>
        <div>
          <Label htmlFor="type">{t('type')}</Label>
          <Select value={type} onValueChange={(value: MWMessageType) => setType(value)}>
            <SelectTrigger>
              <SelectValue placeholder="Select a list type" />
            </SelectTrigger>
            <SelectContent>
              {/* <SelectItem value="email">Email</SelectItem>
              <SelectItem value="sms">SMS</SelectItem> */}
              <SelectItem value="viber">Viber</SelectItem>
              <SelectItem value="webhook">Webhook</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isCreatingChannel}
            className="flex gap-1"
            variant="conversionCraft"
          >
            {isCreatingChannel && <LoadingIndicator size="small" />}
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
