import { useCallback, useMemo } from "react";
import { AnyAbility } from "@casl/ability";
import {
  Users,
  LucideIcon,
  LayoutDashboard,
  Globe,
  Flag,
  LayoutGrid,
  ChartNoAxesCombined,
  Building2,
  Settings,
  Handshake,
  CreditCard,
  Shield,
  PictureInPicture,
  AtSign,
  ContactRound,
  MessagesSquare,
  Split,
} from "lucide-react";

import {
  RemixiconComponentType,
  RiAdminLine,
  RiCustomerService2Line,
} from "@remixicon/react";

import { useTranslation } from "@/hooks/useTranslation";
import { useAbility } from "@/hooks/useAbility";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { MESSAGE_WHALE, ROOT_MENU } from "@/featureFlags";
import { useAuthContext } from "@/hooks/useAuth";

type FeaturesCollection = Record<string, boolean>;
type IsAvailableProps = {
  ability: AnyAbility;
  features: FeaturesCollection,
  role: string,
};

type IsAvailable = (props: IsAvailableProps) => boolean;

export type MenuIconType = LucideIcon | RemixiconComponentType;

export type Submenu = {
  href: string;
  label: string;
  icon?: MenuIconType;
};

type SubmenuDescription = Omit<Submenu, 'active'> & {
  isAvailable?: IsAvailable;
};

export type Menu = {
  href: string;
  label: string;
  icon: MenuIconType
  submenus: Submenu[];
};

type MenuDescription = Omit<Menu, 'submenus'> & {
  submenus: SubmenuDescription[];
  isAvailable?: IsAvailable;
};

export type Group = {
  groupLabel: string;
  showLabelOnCollapse?: boolean;
  menus: Menu[];
};

type GroupDescription = Omit<Group, 'menus'> & {
  menus: MenuDescription[];
  isAvailable?: IsAvailable;
};

const appMenuItems: GroupDescription[] = [
  {
    groupLabel: "",
    menus: [
      {
        href: "/dashboard",
        label: "dashboard",
        icon: LayoutDashboard,
        submenus: []
      },
      {
        href: "/websites",
        label: "websites",
        icon: Globe,
        submenus: [],
      },
    ]
  },
  {
    groupLabel: "",
    menus: [
      {
        href: "/popups",
        label: "popups",
        icon: PictureInPicture,
        submenus: [
          {
            href: "/campaigns",
            label: "campaigns",
            icon: Flag,
          },
          {
            href: "/subscribers",
            label: "subscribers",
            icon: Users,
          },
          {
            href: "/popups",
            label: "library",
            icon: LayoutGrid,
          },
          {
            href: "/statistics",
            label: "stats",
            icon: ChartNoAxesCombined,
          },
        ],
      },
      {
        href: "/mw",
        isAvailable: ({ features }) => features[MESSAGE_WHALE],
        label: "messages",
        icon: AtSign,
        submenus: [
          {
            href: "/mw/campaigns",
            label: "campaigns",
            icon: Flag,
          },
          {
            href: "/mw/lists",
            label: "lists",
            icon: ContactRound,
          },
          {
            href: "/mw/channels",
            label: "channels",
            icon: MessagesSquare,
          },
          {
            href: "/mw/segments",
            label: "segments",
            icon: Split,
          },
          {
            href: "/mw/statistics",
            label: "stats",
            icon: ChartNoAxesCombined,
          },
        ],
      },
    ]
  },
  {
    groupLabel: "settings",
    isAvailable: ({ ability }) => ability.can('update', 'organization'),
    menus: [
      {
        href: "",
        label: "organization",
        icon: Building2,
        submenus: [
          {
            href: "/organization/settings",
            label: "organizationSettings",
            icon: Settings,
          },
          {
            href: "/organization/team",
            label: "team",
            icon: RiAdminLine,
          },
          {
            href: "/organization/access-requests",
            label: "accessRequests",
            icon: Handshake,
          },
          {
            href: "/organization/billing",
            label: "billing",
            icon: CreditCard,
          },
        ]
      },
    ]
  }
];

const bottomMenuItems: GroupDescription[] = [
  {
    groupLabel: "",
    isAvailable: ({ features, role }) => features[ROOT_MENU] && role === 'admin',
    menus: [
      {
        href: "",
        label: "Root",
        icon: Shield,
        submenus: [
          {
            href: "/root/pre-registrations",
            label: "Pre Registrations",
          },
        ]
      },
    ]
  },
  {
    groupLabel: "Other",
    showLabelOnCollapse: false,
    menus: [
      {
        href: "/support",
        label: "support",
        icon: RiCustomerService2Line,
        submenus: []
      },
    ]
  },
];

export function useGetMenuList(): { appMenu: Group[]; bottomMenu: Group[] } {
  const { t } = useTranslation('common');
  const { user } = useAuthContext();
  const ability = useAbility();

  const isRootMenuEnabled = useFeatureIsOn(ROOT_MENU);
  const isMessageWhaleEnabled = useFeatureIsOn(MESSAGE_WHALE);

  const features = useMemo(() => ({
    [ROOT_MENU]: isRootMenuEnabled,
    [MESSAGE_WHALE]: isMessageWhaleEnabled,
  })
  , [isRootMenuEnabled, isMessageWhaleEnabled]);

  const checkAvailability = useCallback((isAvailable?: IsAvailable) => 
    isAvailable ? isAvailable({ ability, features, role: user.role }) : true,
    [ability, features, user.role]
  );

  const transformMenu = useCallback((menu: GroupDescription[]) => menu
    .filter(({ isAvailable }) => checkAvailability(isAvailable))
    .map(({ groupLabel, showLabelOnCollapse, menus }) => ({
      groupLabel: t(groupLabel),
      showLabelOnCollapse,
      menus: menus
      .filter(({ isAvailable }) => checkAvailability(isAvailable))
      .map(({ href, label, icon, submenus }) => ({
        href,
        label: t(label),
        icon,
        submenus: submenus
          .filter(({ isAvailable }) => checkAvailability(isAvailable))
          .map(({ href, label, icon }) => ({
            href,
            label: t(label),
            icon,
          })),
      }))
    })),
    [t, checkAvailability]
  );

  const appMenu = useMemo(
    () => transformMenu(appMenuItems),
    [transformMenu]
  );
  
  const bottomMenu = useMemo(
    () => transformMenu(bottomMenuItems),
    [transformMenu]
  );

  return {
    appMenu,
    bottomMenu,
  };
}
