import { AnyObject } from "../common";

export enum MWMessageType {
  EMAIL = 'email',
  VIBER = 'viber',
  SMS = 'sms',
  WEBHOOK = 'webhook',
}

export const MW_MESSAGE_TYPES = Object.values(MWMessageType);

export interface MWMessageBase {
  id: string;
  created: string;
  updated: string;
}

export interface MWMessage extends MWMessageBase {
  content: AnyObject | null;
  campaignId: number;
  templateId: number | null;
  channelId: number | null;
  type: MWMessageType;
}

export type MWMessageBE = {
  id: number | null;
  created_at: string;
  updated_at: string;
  campaign_id: number;
  template_id: number | null;
  channel_id: number | null;
  content: AnyObject | null;
  type: MWMessageType;
};
