import { useState, ReactNode, useCallback } from 'react';
import { ModalType, useModalStore } from '../stores/modals';
import { ModalContext } from '@/contexts/ModalContext';

interface ModalProviderProps {
  children: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setModal = useModalStore(state => state.setModal);

  const openModal = useCallback((modalType: ModalType) => {
    setModal(modalType, true);
    setIsModalOpen(true);
  }, [setModal]);

  const closeModal = useCallback((modalType: ModalType) => {
    setModal(modalType, false);
    setIsModalOpen(false);
  }, [setModal]);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
}
