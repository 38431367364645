import { MWSegment, MWSegmentBE } from '@/types/message-whale/mwSegment';

export const mapMWSegmentBEsToDTO = (segmentBE: MWSegmentBE): MWSegment => {
  return {
    id: segmentBE.id.toString(),
    name: segmentBE.name,
    created: segmentBE.created_at,
    updated: segmentBE.updated_at,
    query: segmentBE.query,
  };
};

export const mapMWSegmentsBEsToDTO = (
  segmentBEArray: MWSegmentBE[]
): MWSegment[] => {
  return segmentBEArray.map((segmentBE) => {
    return mapMWSegmentBEsToDTO(segmentBE);
  });
};

export const mapMWSegmentDTOToBEForCreate = (
  segment: Partial<MWSegment>
): Partial<MWSegmentBE> => {
  return {
    name: segment.name,
    query: segment.query,
  };
};

export const mapMWSegmentDTOToBEForUpdate = (
  segment: Partial<MWSegment>
): Partial<MWSegmentBE> => {
  return {
    name: segment.name,
    query: segment.query,
  };
};
