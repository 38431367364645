import { useCallback, useEffect, useMemo, useState } from "react";

import {
  CalendarIcon,
  Dot,
  MailIcon as EnvelopeClosedIcon,
  ScanFace as FaceIcon,
  GitCompareArrows as GearIcon,
  PersonStanding as PersonIcon,
  Plus,
  RocketIcon,
} from "lucide-react"
 
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "@/components/ui/command"
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { useTranslation } from "@/hooks/useTranslation";
import { useGetMenuList } from "@/menu";
import { useNavigate } from "react-router-dom";
import { AnyObject } from "@/types/common";
import { useModalContext } from "@/hooks/useModalContext";

export function NavbarSearch() {
  const { t } = useTranslation('common');
  const { openModal } = useModalContext();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const menuItems = useGetMenuList();

  const mergedMenuItems = useMemo(() => {
    const elems = [...menuItems.appMenu, ...menuItems.bottomMenu];
    let result: AnyObject[] = [];

    for (const item of elems) {
      for (const menu of item.menus) {
        if (menu.submenus.length === 0) {
          result.push({
            label: menu.label,
            href: menu.href,
            icon: menu.icon ?? Dot,
          });
        } else {
          result = result.concat(menu.submenus.map((submenu) => ({
            label: submenu.label,
            href: submenu.href,
            icon: submenu.icon ?? Dot,
          })));
        }
      }
    }

    return result;
  }, [menuItems]);

  const runCommand = useCallback((command: () => unknown) => {
    setOpen(false)
    command()
  }, []);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, []);

  return (
    <>
      <Button
        variant="outline"
        className={cn(
          "relative h-10 w-full justify-start text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40 lg:w-96"
        )}
        onClick={() => setOpen(true)}
      >
        <span className="hidden lg:inline-flex">{t('findSomething')}...</span>
        <span className="inline-flex lg:hidden">{t('search')}...</span>
        <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.5rem] hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-xs">⌘</span>K
        </kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder={t('typeCommandOrSearch')} />
        <CommandList>
          <CommandEmpty>{t('noResultsFound')}</CommandEmpty>
          <CommandGroup heading={t('navigation')}>
            {
              mergedMenuItems.map(({icon: MenuIcon, ...item}, index) => (
                <CommandItem key={index} asChild
                  value={item.label}
                  
                >
                  <Button 
                    className="flex gap-2 w-full justify-start"
                    variant="ghost"
                    onClick={() => {
                      console.log('onClick');
                      runCommand(() => navigate(item.href))
                    }}
                  >
                    <MenuIcon className="mr-2 h-4 w-4" />
                    <span>{item.label}</span>
                  </Button>
                </CommandItem>
              ))
            }
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading={t('commands')}>
            <CommandItem asChild>
              <Button className="flex gap-2 w-full justify-start" variant="ghost"
                onClick={() => runCommand(() => openModal('createNewBasicWebsite'))}
              >
                <Plus className="mr-2 h-4 w-4" />
                <span>{t('createNewWebsite')}</span>
                {/* <CommandShortcut>⌘P</CommandShortcut> */}
              </Button>
            </CommandItem>
            <CommandItem asChild>
              <Button className="flex gap-2 w-full justify-start" variant="ghost"
                onClick={() => runCommand(() => openModal('createNewCampaign'))}
              >
                <Plus className="mr-2 h-4 w-4" />
                <span>{t('createNewCampaign')}</span>
                {/* <CommandShortcut>⌘P</CommandShortcut> */}
              </Button>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
}